<template>
    <o-notifications />
    <router-view />
</template>

<script>
import ONotifications from '@organisms/o-notifications-api'
export default {
    name: 'App',
    components: {
        ONotifications,
    },
}
</script>

<style lang="scss">
@import 'theme';
#app {
    height: 100%;
}
</style>
