import userService from '@/services/user-service'
import i18n from '@/i18n'

export const state = {
    users: [],
    usersLoaded: false,
    usersCurPage: 1,
    totalUsers: 0,
    usersLimit: 25,
    usersSorting: {
        sort: 1,
        order_by: 'email',
    },
    usersFilter: {
        is_active: null,
        is_archived: false,
    },
    activeUser: {},
    columnsMobile: ['type', 'version_code', 'version_name', 'date'],
    columnsUsers: ['email', 'name', 'surname', 'is_active'],
    availableUsers: [
        {
            key: 'email',
            label: 'Email',
            sortable: true,
            disabled: true,
            width: 140,
        },
        {
            key: 'name',
            label: 'First name',
            sortable: true,
            width: 110,
        },
        {
            key: 'surname',
            label: 'Last name',
            sortable: true,
            width: 110,
        },
        {
            key: 'is_active',
            label: 'Active',
            sortable: true,
            width: 110,
        },
    ],
    availableMobile: [
        {
            key: 'type',
            label: 'Type',
            sortable: true,
            width: 140,
        },
        {
            key: 'version_code',
            label: 'Version code',
            sortable: true,
            width: 110,
        },
        {
            key: 'version_name',
            label: 'Version name',
            sortable: true,
            width: 110,
        },
        {
            key: 'date',
            label: 'Date',
            sortable: true,
            width: 110,
        },
    ],
}

export const mutations = {
    SET_CURRENT_PAGE(state, page) {
        state.usersCurPage = page
    },
    SET_TOTAL_USERS(state, value) {
        state.totalUsers = value
    },
    SET_USERS_LOADED(state, value) {
        state.usersLoaded = value
    },
    GET_USERS_LIST(state, data) {
        state.users = []
        state.users = data
    },
    SET_USERS_SORTING(state, data) {
        state.usersSorting.sort = data.value
        state.usersSorting.order_by = data.key
    },
    SET_USERS_FILTER(state, data) {
        state.usersFilter.is_active = data.is_active
        state.usersFilter.is_archived = data.is_archived
    },
    SET_ACTIVE_USER(state, data) {
        state.activeUser = data
    },
    UPDATE_USERS_COLUMNS(state, columns) {
        state.columnsUsers = columns
    },
    UPDATE_MOBILE_COLUMNS(state, columns) {
        state.columnsMobile = columns
    },
    UPDATE_USERS_AVAILABLE(state, data) {
        state.availableUsers = data
    },
    UPDATE_MOBILE_AVAILABLE(state, data) {
        state.availableMobile = data
    },
}

export const getters = {
    translatedColumnsUsers(state) {
        const translationKeys = {
            email: 'w_email',
            name: 'w_name',
            surname: 'w_surname',
            is_active: 'sm_0056_Active',
        }

        return state.columnsUsers.map(column => {
            const translationKey = translationKeys[column] || ''
            return i18n.global.t(translationKey)
        })
    },
    translatedAvailableUsers(state) {
        const translationKeys = {
            email: 'w_email',
            name: 'w_name',
            surname: 'w_surname',
            is_active: 'sm_0052_Active',
        }

        return state.availableUsers.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
    translatedColumnsMobile(state) {
        const translationKeys = {
            type: 'sm_0229_type',
            version_code: 'id_0178_version_code',
            version_name: 'id_0179_version_name',
            date: 'sm_0086_date',
        }

        return state.columnsMobile.map(column => {
            const translationKey = translationKeys[column] || ''
            return i18n.global.t(translationKey)
        })
    },
    translatedAvailableMobile(state) {
        const translationKeys = {
            type: 'sm_0229_type',
            version_code: 'id_0178_version_code',
            version_name: 'id_0179_version_name',
            date: 'sm_0086_date',
        }

        return state.availableMobile.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
}

export const actions = {
    async getUsersList({ commit, state }, queries) {
        commit('SET_USERS_LOADED', false)
        await userService.getUsers(queries).then(resp => {
            commit('GET_USERS_LIST', resp.data.srv_engineers)
            commit('SET_USERS_LOADED', true)
            commit('SET_TOTAL_USERS', resp.data.count)
        })
    },

    setUsersCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    setUsersSorting({ commit }, data) {
        commit('SET_USERS_SORTING', data)
    },
    setUsersFilter({ commit }, data) {
        commit('SET_USERS_FILTER', data)
    },
    setActiveUser({ commit }, data) {
        commit('SET_ACTIVE_USER', data)
    },
    refreshUsersList({ commit }, data) {
        commit('GET_USERS_LIST', [])
    },
    updateUsersColumns({ commit }, columns) {
        commit('UPDATE_USERS_COLUMNS', columns)
    },
    updateUsersAvailable({ commit }, data) {
        commit('UPDATE_USERS_AVAILABLE', data)
    },
    updateMobileColumns({ commit }, columns) {
        commit('UPDATE_MOBILE_COLUMNS', columns)
    },
    updateMobileAvailable({ commit }, data) {
        commit('UPDATE_MOBILE_AVAILABLE', data)
    },
}
