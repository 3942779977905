import kitSettingsService from '@/services/kit-settings-service'
import i18n from '@/i18n'

export const state = {
    devices: [],
    devicesLoaded: false,
    devicesCurPage: 1,
    devicesLimit: 25,
    totalDevices: 0,
    totalPages: null,
    devicesSorting: {
        sort: 1,
        order_by: 'type',
    },
    availableDevices: [
        {
            key: 'serial_number',
            label: 'Serial number',
            sortable: false,
            disabled: true,
            width: 140,
        },
        {
            key: 'mac_address',
            label: 'MAC address',
            sortable: false,
            width: 110,
        },
        {
            key: 'model_name',
            label: 'Device model',
            sortable: true,
            width: 110,
        },
        {
            key: 'type',
            label: 'Device type',
            sortable: true,
            width: 110,
        },
        {
            key: 'vendor',
            label: 'Vendor',
            sortable: true,
            width: 110,
        },
    ],
    columnsDevice: [
        'serial_number',
        'mac_address',
        'model_name',
        'type',
        'vendor',
    ],
}

export const mutations = {
    SET_DEVICES_LOADED(state, value) {
        state.devicesLoaded = value
    },
    SET_DEVICE_SORTING(state, data) {
        state.devicesSorting.sort = data.value
        state.devicesSorting.order_by = data.key
    },
    GET_DEVICES_LIST(state, data) {
        state.devices = []
        state.devices = data
    },
    SET_TOTAL_DEVICES(state, value) {
        if (value) {
            state.totalDevices = value
        } else {
            state.totalDevices = state.devicesModules.length
        }
    },
    SET_CURRENT_PAGE(state, page) {
        state.devicesCurPage = page
    },
    UPDATE_DEVICES_COLUMNS(state, columns) {
        state.columnsDevice = columns
    },
    UPDATE_DEVICES_AVAILABLE(state, data) {
        state.availableDevices = data
    },
}

export const getters = {
    translatedAvailableDevices(state) {
        const translationKeys = {
            serial_number: 'id_0056_serial_number',
            mac_address: 'id_0057_mac_address',
            model_name: 'id_0058_device_model',
            type: 'id_0051_device_type',
            vendor: 'id_0052_vendor',
        }

        return state.availableDevices.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
}

export const actions = {
    async getDevicesList({ commit, state }, queries) {
        commit('SET_DEVICES_LOADED', false)
        await kitSettingsService.getDeviceList(queries).then(resp => {
            commit(
                'GET_DEVICES_LIST',
                resp.data.device_models.map(device => {
                    return {
                        ...device,
                        type:
                            device.type === 'Blood pressure monitor'
                                ? i18n.global.t(
                                      'id_0039_blood_pressure_monitor'
                                  )
                                : device.type === 'Glucometer'
                                ? i18n.global.t('id_0017_glucometer')
                                : device.type === 'Main unit'
                                ? i18n.global.t('id_0038_main_unit')
                                : device.type === 'Pulse oximeter'
                                ? i18n.global.t('id_0019_pulse_oximeter')
                                : device.type === 'Thermometer'
                                ? i18n.global.t('id_0043_thermometer')
                                : device.type === 'ECG'
                                ? i18n.global.t('w_ecg')
                                : device.type === 'Scales'
                                ? i18n.global.t('id_0044_scales')
                                : device.type,
                    }
                })
            )
            commit('SET_DEVICES_LOADED', true)
            commit('SET_TOTAL_DEVICES', resp.data.count)
        })
    },

    setDevicesSorting({ commit }, data) {
        commit('SET_DEVICE_SORTING', data)
    },
    setCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    updateDevicesColumns({ commit }, columns) {
        commit('UPDATE_DEVICES_COLUMNS', columns)
    },
    updateDevicesAvailable({ commit }, data) {
        commit('UPDATE_DEVICES_AVAILABLE', data)
    },
}
