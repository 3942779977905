import { defineRule } from 'vee-validate'
import i18n from '@/i18n'

defineRule('required', value => {
    if (!value || !value.length) {
        return i18n.global.t('sm_0178_field_required')
    }
    return true
})
defineRule('name', value => {
    let check = /[\r\n\t\u0020-\uFFFF]*/.test(value)
    let length = value.length <= 30 && value.length >= 2
    if (length && (value.length ? !!value.trim().length : true) && check) {
        return true
    } else {
        return i18n.global.t('w_firstname_error')
    }
})
defineRule('lastName', value => {
    let check = /[\r\n\t\u0020-\uFFFF]*/.test(value)
    let length = value.length <= 30 && value.length >= 2
    if (length && (value.length ? !!value.trim().length : true) && check) {
        return true
    } else {
        return i18n.global.t('w_lastname_error')
    }
})
defineRule('email', value => {
    // let check =
    //     /(^[A-Za-z0-9._%+-]{1,78})@(([A-Za-z0-9-]{1,78}\.)[A-Za-z0-9-]{1,78}(\.[A-Za-z0-9-]{1,78})?)$/.test(
    //         value
    //     )
    let check =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,80}$/.test(
            value
        )
    const atIndex = value.indexOf('@')
    let domainPart
    if (atIndex !== -1) {
        domainPart = value.substring(atIndex + 1)
    }
    if (check && domainPart.length <= 82) {
        return true
    } else {
        return i18n.global.t('email')
    }
})
defineRule('password', value => {
    let check =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/.test(
            value
        )
    if (check) {
        return true
    } else {
        return i18n.global.t('w_passwordValidError')
    }
})
