import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@state/store'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.name === 'settings') {
        next(false)
    } else {
        const { authorize } = to.meta
        const isTokenExists = !!store.state.auth.access_token
        localStorage.setItem('localeLang', store.state.auth.language)

        if (authorize !== false && !isTokenExists && to.name !== 'login') {
            next({ name: 'login', query: { returnUrl: to.fullPath } })
        } else if (
            authorize !== false &&
            isTokenExists &&
            to.name === 'login'
        ) {
            next({ name: 'users' })
        } else {
            next()
        }
    }
})

export default router
