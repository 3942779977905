<template>
    <div class="o-notifications-api-wrap">
        <notifications
            class="o-notifications-api"
            :duration="7000"
            :width="280"
            position=""
            group="api-errors"
        >
            <template
                v-slot:body="{
                    item: {
                        data: { url, method },
                    },
                }"
            >
                <div class="vue-notification-template vue-notification error">
                    <span class="title">
                        <span>Request Error</span> <br />
                        <span>{{ url }}</span> <br />
                        <span>{{ method }}</span> <br />
                    </span>
                    <button class="close" @click="close"></button>
                </div>
            </template>
        </notifications>
        <notifications
            class="o-notifications-api-error"
            :duration="10000"
            width="100%"
            position=""
            group="api-error-custom"
        >
            <template
                v-slot:body="{
                    item: {
                        data: { text, title },
                    },
                    close,
                }"
            >
                <div class="vue-notification-template vue-notification error">
                    <span v-if="title" class="title">
                        {{ title }}
                    </span>
                    <span v-if="text" class="text">
                        {{ text }}
                    </span>
                    <button class="close-error" @click="close"></button>
                </div>
            </template>
        </notifications>
        <notifications
            :duration="5000"
            :pauseOnHover="true"
            class="o-notifications-api-info"
            group="api-info"
            position=""
            width="100%"
        >
            <template
                v-slot:body="{
                    item: {
                        data: { text, data, link, button },
                    },
                    close,
                }"
            >
                <div class="vue-notification-template vue-notification info">
                    <span v-if="link" class="text">
                        {{ text
                        }}<router-link
                            :to="{
                                name: link,
                                params: { id: data.id },
                            }"
                            >"{{ data.email }}"</router-link
                        >
                    </span>
                    <div v-else-if="button" class="button">
                        <span>{{ button.text }}</span>
                        <span
                            class="cancel-btn"
                            @click="actionButton(button, close)"
                            >{{ $t('w_cancel') }}</span
                        >
                    </div>
                    <span v-else class="text">
                        {{ text }}
                    </span>
                    <button class="close" @click="close"></button>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
import { notificationsComputed } from '@state/helpers'

export default {
    name: 'ONotificationsApi',
    methods: {
        actionButton(button, close) {
            button.action()
            close()
        },
    },
    computed: {
        ...notificationsComputed,
    },
    watch: {
        error: {
            handler({ data }) {
                // if (!url && !method) {
                if (data) {
                    this.$notify({
                        group: 'api-error-custom',
                        type: 'error',
                        data: {
                            title: data?.title,
                            text: data?.text,
                        },
                    })
                }
                // }
            },
            deep: true,
        },
        info: {
            handler(data) {
                if (data.data) {
                    this.$notify({
                        group: 'api-info',
                        type: 'info',
                        data: {
                            text: data.data.text,
                            data: data.data.data,
                            link: data.data.link,
                            button: data.data.button,
                        },
                    })
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@design';

.o-notifications-api {
    width: 100%;
}

.o-notifications-api-wrap {
    position: fixed;
    right: 3px;
    bottom: 3px;
    z-index: 999;
}

.vue-notification-group {
    position: static !important;
}

.vue-notification-template {
    padding: 12px 18px;
    margin: 25px;
    font-size: 14px;
    background: linear-gradient(
            0deg,
            rgba(2, 36, 156, 0.1),
            rgba(2, 36, 156, 0.1)
        ),
        #ffffff;
    border: none;
    border-radius: 8px;
    box-shadow: 4px 4px 24px rgba(51, 51, 51, 0.08);

    .title {
        display: block;
        margin-bottom: 10px;
        line-height: 17px;
        word-break: break-word;
    }

    .text {
        line-height: 16px;
        word-break: break-word;
        a {
            color: $color-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .button {
        .cancel-btn {
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;
            padding-left: 5px;
        }
    }

    &.info {
        display: flex;
        justify-content: space-between;
        color: $color-blue;
    }

    &.error {
        color: #d84f4d;
        background: #fbd6d5;
        box-shadow: 0 0 4px rgba(255, 62, 62, 0.4);
    }
    .close {
        position: relative;
        top: -1px;
        width: 8px;
        height: 8px;
        margin-left: 14px;
        border: none;
        &::after,
        &::before {
            position: absolute;
            width: 2px;
            height: 12px;
            content: '';
            background-color: $color-blue;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }

    .close-error {
        position: relative;
        top: -10px;
        width: 8px;
        height: 8px;
        margin-left: 14px;
        border: none;
        background: #fbd6d5;
        &::after,
        &::before {
            position: absolute;
            width: 2px;
            height: 12px;
            content: '';
            background-color: #d84f4d;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
</style>
