import Equipment from './modules/equipment'
import Auth from './modules/auth'

export default [
    ...Equipment,
    ...Auth,

    // otherwise redirect to home
    //{ path: '/:pathMatch(.*)*', name: 'not-found' },
    { path: '/:pathMatch(.*)*', redirect: '/' },
]
