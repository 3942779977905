import { $axios } from './base-service'

export default {
    getUsers(queries) {
        let resource = '/service-engineer/list'
        let data = {}
        if (queries) {
            if (queries.sort) {
                data.column_name = queries.sort.name
                data.by_descending = queries.sort.by_descending
            }
            if (queries.search) {
                data.query = queries.search
            }
            if (queries.page) {
                data.page = queries.page
            }
            if ('is_active' in queries && queries.is_active !== null) {
                data.statuses = queries.is_active ? 'active' : 'not_active'
            }
            if (queries.is_archived) {
                data.statuses = 'archived'
            }
        }
        return $axios.post(`${resource}`, data)
    },
    addUser(data) {
        let resource = '/service-engineer'
        return $axios.post(`${resource}`, data)
    },
    getUser(id) {
        let resource = `/service-engineer/${id}/`
        return $axios.get(`${resource}`)
    },
    updateUser(data) {
        let resource = `/service-engineer/`
        return $axios.put(`${resource}`, data)
    },
}
