const meta = { authorize: 'service_engineer' }

export default [
    {
        path: '/',
        component: () => import('@pages/p-equipment-base'),
        children: [
            {
                path: '',
                name: 'equipment-main',
                meta,
                component: () => import('@templates/t-equipment-start-page'),
            },
            {
                path: '/users',
                name: 'users',
                meta,
                component: () => import('@templates/t-users'),
                redirect: {
                    name: 'users-list',
                },
                children: [
                    {
                        path: '/users/all',
                        name: 'users-list',
                        meta,
                        component: () => import('@organisms/o-users'),
                    },
                    {
                        path: '/users/add',
                        name: 'user-add',
                        meta,
                        component: () => import('@organisms/o-user-add'),
                    },
                    {
                        path: '/users/:id',
                        name: 'user-info',
                        component: () => import('@organisms/o-user-view'),
                    },
                    {
                        path: '/users/:id/edit',
                        name: 'user-edit',
                        component: () => import('@organisms/o-user-edit'),
                    },
                ],
            },
            {
                path: '/kits',
                name: 'kits',
                meta,
                component: () => import('@templates/t-kits'),
                redirect: {
                    name: 'kits-list',
                },
                children: [
                    {
                        path: '/kits/all',
                        name: 'kits-list',
                        meta,
                        component: () => import('@organisms/o-kits'),
                    },
                    {
                        path: '/kits/:id',
                        name: 'kit-info',
                        meta,
                        component: () => import('@organisms/o-kit-view'),
                    },
                    {
                        path: '/kits/:id/edit',
                        name: 'kit-edit',
                        meta,
                        component: () => import('@organisms/o-kit-edit'),
                    },
                    {
                        path: '/kits/:id/:path:target',
                        name: 'kit-transfer',
                        meta,
                        component: () => import('@organisms/o-kit-transfer'),
                    },
                ],
            },
            {
                path: '/settings',
                name: 'settings',
                children: [
                    {
                        path: '/settings/activation-list',
                        name: 'activation',
                        meta,
                        component: () => import('@organisms/o-activation'),
                    },
                    {
                        path: '/settings/device-list',
                        name: 'device',
                        meta,
                        component: () => import('@organisms/o-device'),
                    },
                    {
                        path: '/settings/device-modules-list',
                        name: 'device-modules',
                        meta,
                        component: () => import('@organisms/o-device-modules'),
                    },
                ],
                meta: {
                    title: 'Settings',
                },
            },
            {
                path: '/mobile-update',
                name: 'mobile',
                component: () => import('@templates/t-mobile-application'),
                redirect: {
                    name: 'applications-list',
                },
                children: [
                    {
                        path: '/applications/all',
                        name: 'applications-list',
                        meta,
                        component: () =>
                            import('@organisms/o-mobile-application'),
                    },
                    {
                        path: '/applications/add',
                        name: 'applications-add',
                        meta,
                        component: () => import('@organisms/o-application-add'),
                    },
                    {
                        path: '/applications/:id',
                        name: 'applications-info',
                        component: () =>
                            import('@organisms/o-application-view'),
                    },
                ],
            },
        ],
    },
]
