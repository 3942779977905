import kitSettingsService from '@/services/kit-settings-service'
import i18n from '@/i18n'

export const state = {
    devicesModules: [],
    devicesModulesLoaded: false,
    devicesModulesCurPage: 1,
    devicesModulesLimit: 25,
    totalDevicesModules: 0,
    totalPages: null,
    devicesModulesSorting: {
        sort: 1,
        order_by: 'model_name',
    },
    availableModules: [
        {
            key: 'model_name',
            label: 'Model name',
            sortable: true,
            disabled: true,
            width: 140,
        },
        {
            key: 'system_name',
            label: 'System name',
            sortable: true,
            width: 110,
        },
        {
            key: 'type',
            label: 'Device type',
            sortable: true,
            width: 110,
        },
        {
            key: 'vendor',
            label: 'Vendor',
            sortable: true,
            width: 110,
        },
    ],
    columnsModules: ['model_name', 'system_name', 'type', 'vendor'],
}

export const mutations = {
    SET_DEVICES_LOADED(state, value) {
        state.devicesModulesLoaded = value
    },
    SET_DEVICE_SORTING(state, data) {
        state.devicesModulesSorting.sort = data.value
        state.devicesModulesSorting.order_by = data.key
    },
    GET_DEVICES_LIST(state, data) {
        state.devicesModules = []
        state.devicesModules = data
    },
    SET_TOTAL_DEVICES(state, value) {
        if (value) {
            state.totalDevicesModules = value
        } else {
            state.totalDevicesModules = state.devicesModules.length
        }
    },
    SET_CURRENT_PAGE(state, page) {
        state.devicesModulesCurPage = page
    },
    UPDATE_MODULES_COLUMNS(state, columns) {
        state.columnsModules = columns
    },
    UPDATE_MODULES_AVAILABLE(state, data) {
        state.availableModules = data
    },
}

export const getters = {
    translatedAvailableDevicesModules(state) {
        const translationKeys = {
            model_name: 'id_0058_device_model',
            system_name: 'id_0050_system_name',
            type: 'id_0051_device_type',
            vendor: 'id_0052_vendor',
        }

        return state.availableModules.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
}

export const actions = {
    async getDevicesModulesList({ commit, state }, queries) {
        commit('SET_DEVICES_LOADED', false)
        await kitSettingsService.getDeviceModulesList(queries).then(resp => {
            commit(
                'GET_DEVICES_LIST',
                resp.data.device_models.map(device => {
                    return {
                        ...device,
                        type:
                            device.type === 'Blood pressure monitor'
                                ? i18n.global.t(
                                      'id_0039_blood_pressure_monitor'
                                  )
                                : device.type === 'Glucometer'
                                ? i18n.global.t('id_0017_glucometer')
                                : device.type === 'Main unit'
                                ? i18n.global.t('id_0038_main_unit')
                                : device.type === 'Pulse oximeter'
                                ? i18n.global.t('id_0019_pulse_oximeter')
                                : device.type === 'Thermometer'
                                ? i18n.global.t('id_0043_thermometer')
                                : device.type === 'ECG'
                                ? i18n.global.t('w_ecg')
                                : device.type === 'Scales'
                                ? i18n.global.t('id_0044_scales')
                                : device.type,
                    }
                })
            )
            commit('SET_DEVICES_LOADED', true)
            commit('SET_TOTAL_DEVICES', resp.data.count)
        })
    },

    setDevicesModulesSorting({ commit }, data) {
        commit('SET_DEVICE_SORTING', data)
    },
    setCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    updateModulesColumns({ commit }, columns) {
        commit('UPDATE_MODULES_COLUMNS', columns)
    },
    updateModulesAvailable({ commit }, data) {
        commit('UPDATE_MODULES_AVAILABLE', data)
    },
}
