import { getLanguagesList } from '@/services/base-service'
import { ref, inject } from 'vue'
import i18n from '@/i18n'

export const state = {
    languagesList: ref([]),
}

export const mutations = {
    SET_LANGUAGES_LIST(state, payload) {
        state.languagesList = payload
    },
}

export const actions = {
    async getLanguages({ commit }) {
        try {
            const $i18n = inject('$i18n')
            const langsList = []
            const {
                data: { languages: draftlanguages },
            } = await getLanguagesList()
            draftlanguages.forEach(lang => {
                langsList.push({
                    label: i18n.global.t(`w_${lang.code}`),
                    value: lang.code,
                    ...lang,
                })
            })
            const sortFunction = (a, b) => {
                return a.code.localeCompare(b.code)
            }
            commit('SET_LANGUAGES_LIST', langsList.sort(sortFunction))
        } catch (e) {
            console.error(e)
        }
    },
}
