const meta = { authorize: [] }

export default [
    {
        path: '/login',
        name: 'login',
        meta,
        component: () => import('@pages/p-login'),
    },
]
