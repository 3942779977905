import { $axios } from '@services/base-service'
import AuthService from '@services/auth-service'
import store from '@state/store'
import router from '@router'

export const state = {
    access_token: '',
    refresh_token: '',
    storage_token: '',
    role: '',
    email: '',
    userName: '',
    userSurname: '',
    language: '',
    id: '',
}

export const mutations = {
    // here we got user token and information
    SET_CURRENT_USER(state, data) {
        state.storage_token = data.pair.storage_token
        state.access_token = data.pair.access_token
        state.refresh_token = data.pair.refresh_token
        state.role = data.pair.role
        if (data.service_engineer) {
            state.userName = data.service_engineer.name
            state.userSurname = data.service_engineer.surname
            state.language = data.service_engineer.language
            state.email = data.service_engineer.email
            state.id = data.service_engineer.id
        }
        setDefaultAuthHeaders(state)
    },
    UPDATE_LANGUAGE_IN_STORE(state, payload) {
        state.language = payload
    },
    // CHANGE_REMEMBER_ME(state) {
    //     state.isRememberMe = !state.isRememberMe
    // },
    RESET_AUTH_HEADERS(state) {
        state.access_token = ''
        state.refresh_token = ''
        state.storage_token = ''
        state.email = ''
        setDefaultAuthHeaders(state)
    },
}

export const getters = {
    // isAdmin(state) {
    //     return state.role === Role.admin
    // },

    // isDoctor(state) {
    //     return state.role === Role.doctor
    // },

    // isDistributor(state) {
    //     return state.role === Role.distributor
    // },
    isServiceEngineer(state) {
        return state.role === 'service_engineer'
    },
    // isOwner(state) {
    //     return state.role === Role.owner
    // },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state }) {
        setDefaultAuthHeaders(state)

        // turn on if validation needed
        // dispatch('validate')
    },

    // changeRememberMe({ commit }) {
    //     commit('CHANGE_REMEMBER_ME')
    // },

    // Logs in the current user.
    logIn({ commit }, data) {
        // turn on if validation needed
        return AuthService.login(data).then(response => {
            let userData = { ...response.data }
            commit('SET_CURRENT_USER', userData)
            return response
        })
    },

    // resetPassword({ commit }, data) {
    //     commit('SET_CURRENT_USER', data)
    // },

    // Logs out the current user.
    logOutRequest({ commit }) {
        commit('RESET_STORE', {}, { root: true })
        AuthService.logOut()
    },

    // resetAuthHeaders({ commit }) {
    //     commit('RESET_AUTH_HEADERS')
    // },
}
// ===
// Private helpers
// ===
// Working point variant

function headersDetection(headers) {
    if (
        headers['x-token-access'] &&
        headers['x-token-refresh'] &&
        headers['x-token-storage']
    ) {
        const access_token = headers['x-token-access']
        const refresh_token = headers['x-token-refresh']
        const storage_token = headers['x-token-storage']
        const data = {
            pair: {
                access_token: access_token,
                refresh_token: refresh_token,
                storage_token: storage_token,
            },
        }

        store.commit('auth/SET_CURRENT_USER', data)
    }
}

$axios.interceptors.response.use(
    response => {
        if (response.status === -1) {
            console.log('---response.status', response.status)
        }
        if (response && response.headers) {
            headersDetection(response.headers)
        }

        return response
    },
    error => {
        if (error.response && error.response.status === -1) {
            console.error('---response.status', error.response.status)
        }
        const status = error.response ? error.response.status : 0
        const originalRequest = error.config

        if (status === 401) {
            if (!originalRequest._retry) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (
                            store.state.auth.access_token &&
                            originalRequest.headers['x-token-access'] !==
                                store.state.auth.access_token
                        ) {
                            originalRequest.headers['x-token-access'] =
                                store.state.auth.access_token
                            originalRequest.headers['x-token-refresh'] =
                                store.state.auth.refresh_token
                            if (store.state.auth.isRememberMe) {
                                originalRequest.headers['x-token-storage'] =
                                    store.state.auth.storage_token
                            }
                            originalRequest._retry = true
                            resolve($axios(originalRequest))
                        } else {
                            if (store.state.auth.access_token) {
                                store.dispatch('auth/logOut')
                                store.commit('auth/RESET_AUTH_HEADERS')
                                router.push({ path: '/login' })
                            } else {
                                reject()
                            }
                        }
                    }, 2000)
                })
            } else {
                if (store.state.auth.access_token) {
                    store.dispatch('auth/logOut')
                    store.commit('auth/RESET_AUTH_HEADERS')
                    router.push({ path: '/login' })
                }
            }
        } else if (error.response && error.response.headers) {
            headersDetection(error.response.headers)
        }

        return Promise.reject(error)
    }
)

function setDefaultAuthHeaders(state) {
    $axios.defaults.headers.common['x-token-access'] = state.access_token
        ? state.access_token
        : ''
    $axios.defaults.headers.common['x-token-refresh'] = state.refresh_token
        ? state.refresh_token
        : ''
    // if (state.isRememberMe) {
    $axios.defaults.headers.common['x-token-storage'] = state.storage_token
        ? state.storage_token
        : ''
    // }
}
