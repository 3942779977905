import axios from 'axios'

if (location.hostname === 'localhost') {
    config.VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL
}

const baseDomain = `${config.VUE_APP_BASE_URL}`
const baseURL = `${baseDomain}/api/v1/`

const getLanguagesList = () => {
    const resource = 'content/languages'
    return $axios.get(`${resource}`)
}

const changeProfile = data => {
    const resource = 'service-engineer'
    return $axios.put(`${resource}`, data)
}

const $axios = axios.create({
    baseURL,
})

export { $axios, getLanguagesList, changeProfile }

export default $axios
