import { $axios } from './base-service'
import axios from 'axios'

export default {
    login(data) {
        const resource = '/operators/login'
        return $axios.post(`${resource}`, data)
    },
    logOut(data) {
        const resource = '/operators/logout'
        return $axios.post(`${resource}`, data)
    },
}
