import { $axios } from './base-service'

export default {
    getDeviceModulesList(queries) {
        let resource = '/devices-model/list'
        let data = {}
        if (queries) {
            if (queries.sort) {
                data.column_name = queries.sort.name
                data.by_descending = queries.sort.by_descending
            }
            if (queries.search) {
                data.query = queries.search
            }
            if (queries.page) {
                data.page = queries.page
            }
            if ('is_active' in queries && queries.is_active !== null) {
                data.statuses = queries.is_active ? 'active' : 'not_active'
            }
        }
        return $axios.post(`${resource}`, data)
    },

    getDeviceModelsAvailableList(data) {
        let resource = `/devices-model/list`
        return $axios.post(`${resource}`, data)
    },

    getDeviceList(queries) {
        let resource = '/kit-devices/list'
        let data = {}
        if (queries) {
            if (queries.sort) {
                data.column_name = queries.sort.name
                data.by_descending = queries.sort.by_descending
            }
            if (queries.search) {
                data.query = queries.search
            }
            if (queries.page) {
                data.page = queries.page
            }
            if ('is_active' in queries && queries.is_active !== null) {
                data.statuses = queries.is_active ? 'active' : 'not_active'
            }
        }
        return $axios.post(`${resource}`, data)
    },
    kitActivate(code) {
        let resource = `/devices/${code}`
        return $axios.get(`${resource}`)
    },
    getDeviceModelList() {
        let resource = '/devices-model'
        return $axios.get(`${resource}`)
    },
    getDeviceModelListForId(id, query) {
        let resource = `/devices-model?kit_id=${id}&query=${query}`
        return $axios.get(`${resource}`)
    },
    kitActivation(data) {
        let resource = '/kit'
        return $axios.post(`${resource}`, data)
    },
}
