import kitService from '@/services/kit-service'
import i18n from '@/i18n'

export const state = {
    kits: [],
    kitsLoaded: false,
    kitsCurPage: 1,
    totalKits: 0,
    totalPages: null,
    kitsLimit: 25,
    kitsSorting: {
        sort: 1,
        order_by: 'created_at',
    },
    kitsFilter: {},
    activeKit: {},
    distributorsList: [],
    customersList: [],
    columnsKits: [
        'serial_number',
        'created_at',
        'state',
        'location',
        'distributor',
        'customer',
    ],
    availableKits: [
        {
            key: 'serial_number',
            label: 'Serial number',
            sortable: true,
            disabled: true,
            width: 110,
        },
        {
            key: 'id',
            label: 'Kit ID',
            sortable: false,
            width: 140,
        },
        {
            key: 'created_at',
            label: 'Creation date',
            sortable: true,
            width: 140,
        },
        {
            key: 'state',
            label: 'Status',
            sortable: true,
            width: 110,
        },
        {
            key: 'mac_address',
            label: 'MAC address',
            sortable: false,
            width: 110,
        },
        {
            key: 'location',
            label: 'Location',
            sortable: true,
            width: 110,
        },
        {
            key: 'distributor',
            label: 'Distributor',
            sortable: true,
            width: 110,
        },
        {
            key: 'customer',
            label: 'Customer',
            sortable: true,
            width: 140,
        },
    ],
}

export const mutations = {
    SET_CURRENT_PAGE(state, page) {
        state.kitsCurPage = page
    },
    SET_TOTAL_KITS(state, value) {
        state.totalKits = value
    },
    SET_KITS_LOADED(state, value) {
        state.kitsLoaded = value
    },
    SET_TOTAL_PAGES(state, data) {
        state.totalPages = data
    },
    SET_KITS_LIMIT(state, data) {
        state.kitsLimit = data
    },
    GET_KITS_LIST(state, data) {
        state.kits = []
        state.kits = data
    },
    UPDATE_KITS_LIST(state, data) {
        state.kits.map(kit => {
            if (kit.uuid === data.uuid) {
                for (let key in kit) {
                    kit[key] = data[key]
                }
            }
        })
    },
    SET_KITS_SORTING(state, data) {
        state.kitsSorting.sort = data.value
        state.kitsSorting.order_by = data.key
    },
    SET_KITS_FILTER(state, data) {
        state.kitsFilter = {}
        if (data.statuses) {
            state.kitsFilter.statuses = data.statuses
        }
        if (data.dates) {
            state.kitsFilter.dates = data.dates
        }
        if (data.distributor) {
            state.kitsFilter.distributor = data.distributor
        }
        if (data.customer) {
            state.kitsFilter.customer = data.customer
        }
    },
    SET_ACTIVE_KIT(state, data) {
        state.activeKit = data
    },
    SET_DISTRIBUTOR_LIST(state, data) {
        state.distributorsList = data
    },
    SET_CUSTOMER_LIST(state, data) {
        state.customersList = data
    },
    UPDATE_KITS_COLUMNS(state, columns) {
        state.columnsKits = columns
    },
    UPDATE_KITS_AVAILABLE(state, data) {
        state.availableKits = data
    },
}

export const getters = {
    translatedAvailableKits(state) {
        const translationKeys = {
            serial_number: 'id_0056_serial_number',
            id: 'id_0060_kit_id',
            created_at: 'id_0061_creation_date',
            state: 'w_status',
            mac_address: 'id_0057_mac_address',
            location: 'id_0062_location',
            distributor: 'w_distributor',
            customer: 'id_0048_customer',
        }

        return state.availableKits.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
}

export const actions = {
    async getKitsList({ commit, state }, queries) {
        commit('SET_KITS_LOADED', false)
        await kitService.getKits(queries).then(resp => {
            commit(
                'GET_KITS_LIST',
                resp.data.kits.map(item => {
                    return {
                        ...item,
                        state:
                            item.state === 'active'
                                ? i18n.global.t('sm_0052_Active')
                                : item.state === 'amended'
                                ? i18n.global.t('id_0142_amended')
                                : item.state === 'new'
                                ? i18n.global.t('id_0110_new')
                                : item.state,
                        location:
                            item.location === 'manufacturer'
                                ? i18n.global.t('id_0047_manufacturer')
                                : item.location === 'distributor'
                                ? i18n.global.t('w_distributor')
                                : item.location === 'customer'
                                ? i18n.global.t('id_0048_customer')
                                : item.location === 'patient'
                                ? i18n.global.t('w_patient')
                                : item.location,
                    }
                })
            )
            commit('SET_KITS_LOADED', true)
            commit('SET_TOTAL_KITS', resp.data.count)
        })
    },

    setKitsCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    setKitsSorting({ commit }, data) {
        commit('SET_KITS_SORTING', data)
    },
    setKitsFilter({ commit }, data) {
        commit('SET_KITS_FILTER', data)
    },
    getTotalKits({ commit }) {
        kitService.getKitsCount().then(resp => {
            commit('SET_TOTAL_KITS', resp.data.count)
        })
    },
    setActiveKit({ commit }, data) {
        commit('SET_ACTIVE_KIT', data)
    },
    updateKitsList({ commit }, data) {
        commit('UPDATE_KITS_LIST', data)
    },
    getFilterOptions({ commit }, params) {
        kitService.getFilterOptions(params).then(resp => {
            if (params === 'distributor') {
                commit('SET_DISTRIBUTOR_LIST', resp.data)
            } else if (params === 'customer') {
                commit('SET_CUSTOMER_LIST', resp.data)
            }
        })
    },
    refreshKitsList({ commit }, data) {
        commit('GET_KITS_LIST', [])
    },
    updateKitsColumns({ commit }, columns) {
        commit('UPDATE_KITS_COLUMNS', columns)
    },
    updateKitsAvailable({ commit }, data) {
        commit('UPDATE_KITS_AVAILABLE', data)
    },
}
