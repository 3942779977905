import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@state/store'
import i18n from './i18n.js'

import { vfmPlugin } from 'vue-final-modal'
import FloatingVue from 'floating-vue'
import Notifications from '@kyvg/vue3-notification'

import './vee-validate.js'

createApp(App)
    .use(vfmPlugin)
    .use(i18n)
    .use(store)
    .use(i18n)
    .provide('$i18n', i18n)
    .use(router)
    .use(FloatingVue, {
        themes: {
            'my-theme': {
                $extend: 'tooltip',
                $resetCss: true,
            },
        },
    })
    .use(Notifications)
    .mount('#app')
