import { $axios } from './base-service'

export default {
    postApkFiles(queries) {
        const resource = '/apk-file/list'
        let data = {}
        if (queries) {
            if (queries.sort) {
                data.column_name = queries.sort.name
                data.by_descending = queries.sort.by_descending
            }
            if (queries.search) {
                data.query = queries.search
            }
            if (queries.page) {
                data.page = queries.page
            }
        }
        return $axios.post(`${resource}`, data)
    },
    addApkFile(type, formData) {
        const resource = `/data/applications/${type}`
        return $axios.post(`${resource}`, formData)
    },
    getApkInfo(id) {
        const resource = `/app/${id}`
        return $axios.get(`${resource}`)
    },
    deleteApkFile(id) {
        const resource = `/apk-file/${id}`
        return $axios.delete(`${resource}`)
    },
}
