import apkService from '@/services/apk-service'
import i18n from '@/i18n'

export const state = {
    apkFilesList: [],
    appListLoaded: false,
    appListCurPage: 1,
    totalAppInList: 0,
    appListLimit: 25,
    activeApp: {},
    appListSorting: {
        sort: 1,
        order_by: 'uploaded_at',
    },
    columnsMobile: ['app_type', 'code_version', 'name_version', 'uploaded_at'],
    availableMobile: [
        {
            disabled: true,
            key: 'app_type',
            label: 'Type',
            sortable: true,
            width: 140,
        },
        {
            key: 'code_version',
            label: 'Version code',
            sortable: true,
            width: 110,
        },
        {
            key: 'name_version',
            label: 'Version name',
            sortable: true,
            width: 110,
        },
        {
            key: 'uploaded_at',
            label: 'Date',
            sortable: true,
            width: 110,
        },
    ],
}

export const mutations = {
    SET_APK_FILES_LIST(state, payload) {
        state.apkFilesList = payload
    },
    UPDATE_MOBILE_COLUMNS(state, columns) {
        state.columnsMobile = columns
    },
    SET_APPS_SORTING(state, data) {
        state.appListSorting.sort = data.value
        state.appListSorting.order_by = data.key
    },
    SET_CURRENT_PAGE(state, page) {
        state.appListCurPage = page
    },
    SET_TOTAL_APPS(state, value) {
        if (value) {
            state.totalAppInList = value
        } else {
            state.totalAppInList = state.apkFilesList.length
        }
    },
    UPDATE_MOBILE_AVAILABLE(state, data) {
        state.availableMobile = data
    },
    SET_FILES_LIST_LOADED(state, value) {
        state.appListLoaded = value
    },
    SET_ACTIVE_APP(state, payload) {
        state.activeApp = payload
    },
}

export const getters = {
    translatedColumnsMobile(state) {
        const translationKeys = {
            app_type: 'sm_0229_type',
            code_version: 'id_0178_version_code',
            name_version: 'id_0179_version_name',
            uploaded_at: 'sm_0086_date',
        }

        return state.columnsMobile.map(column => {
            const translationKey = translationKeys[column] || ''
            return i18n.global.t(translationKey)
        })
    },
    translatedAvailableMobile(state) {
        const translationKeys = {
            app_type: 'sm_0229_type',
            code_version: 'id_0178_version_code',
            name_version: 'id_0179_version_name',
            uploaded_at: 'sm_0086_date',
        }

        return state.availableMobile.map(user => {
            const { key, label, ...rest } = user
            const translationKey = translationKeys[key]
            const translatedLabel = i18n.global.t(translationKey)
            return { key, label: translatedLabel, ...rest }
        })
    },
}

export const actions = {
    async getFilesApk({ commit }, queries) {
        commit('SET_FILES_LIST_LOADED', false)
        await apkService.postApkFiles(queries).then(response => {
            const data = response.data
            commit('SET_APK_FILES_LIST', data)
            commit('SET_FILES_LIST_LOADED', true)
            commit('SET_TOTAL_APPS', data.count)
        })
    },
    updateMobileColumns({ commit }, columns) {
        commit('UPDATE_MOBILE_COLUMNS', columns)
    },
    updateMobileAvailable({ commit }, data) {
        commit('UPDATE_MOBILE_AVAILABLE', data)
    },
    setActiveApp({ commit }, data) {
        commit('SET_ACTIVE_APP', data)
    },
    setAppsSorting({ commit }, data) {
        commit('SET_APPS_SORTING', data)
    },
    setAppsCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
}
