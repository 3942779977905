import { mapState, mapGetters, mapActions } from 'vuex'

// Auth data

export const authComputed = {
    ...mapState('auth', {
        storage_token: state => state.storage_token,
        access_token: state => state.access_token,
        refresh_token: state => state.refresh_token,
        userName: state => state.userName,
        userSurname: state => state.userSurname,
        role: state => state.role,
        language: state => state.language,
        email: state => state.email,
        id: state => state.id,
    }),
    ...mapGetters('auth', ['isServiceEngineer']),
}
export const authMethods = {
    ...mapActions('auth', ['logIn', 'logOutRequest']),
}

// Kits

export const kitsComputed = {
    ...mapState('kits', {
        kits: state => state.kits,
        kitsLoaded: state => state.kitsLoaded,
        kitsCurPage: state => state.kitsCurPage,
        totalKits: state => state.totalKits,
        totalPages: state => state.totalPages,
        kitsLimit: state => state.kitsLimit,
        sorting: state => state.kitsSorting,
        kitsFilter: state => state.kitsFilter,
        activeKit: state => state.activeKit,
        distributorsList: state => state.distributorsList,
        customersList: state => state.customersList,
        columnsKits: state => state.columnsKits,
        availableKits: state => state.availableKits,
    }),
    ...mapGetters('kits', ['translatedAvailableKits']),
}

export const kitsMethods = {
    ...mapActions('kits', [
        'setKitsCurPage',
        'getKitsList',
        'setKitsSorting',
        'setKitsFilter',
        'getTotalKits',
        'setActiveKit',
        'updateKitsList',
        'getFilterOptions',
        'refreshKitsList',
        'updateKitsColumns',
        'updateKitsAvailable',
    ]),
}

// Users

export const usersComputed = {
    ...mapState('users', {
        users: state => state.users,
        usersLoaded: state => state.usersLoaded,
        usersCurPage: state => state.usersCurPage,
        totalUsers: state => state.totalUsers,
        usersLimit: state => state.usersLimit,
        sorting: state => state.usersSorting,
        usersFilter: state => state.usersFilter,
        activeUser: state => state.activeUser,
        columnsUsers: state => state.columnsUsers,
        columnsMobile: state => state.columnsMobile,
        availableUsers: state => state.availableUsers,
    }),
    ...mapGetters('users', [
        'translatedAvailableUsers',
        'translatedColumnsUsers',
        'translatedColumnsMobile',
        'translatedAvailableMobile',
    ]),
}

export const usersMethods = {
    ...mapActions('users', [
        'getUsersList',
        'setUsersCurPage',
        'setUsersSorting',
        'setUsersFilter',
        'setActiveUser',
        'refreshUsersList',
        'updateUsersColumns',
        'updateUsersAvailable',
        'updateMobileColumns',
        'updateMobileAvailable',
    ]),
}

export const kitsSettingsDeviceModules = {
    ...mapState('settingsDeviceModules', {
        devicesModules: state => state.devicesModules,
        devicesModulesLimit: state => state.devicesModulesLimit,
        devicesModulesLoaded: state => state.devicesModulesLoaded,
        devicesModulesCurPage: state => state.devicesModulesCurPage,
        totalDevicesModules: state => state.totalDevicesModules,
        totalPages: state => state.totalPages,
        devicesModulesSorting: state => state.devicesModulesSorting,
        availableModules: state => state.availableModules,
        columnsModules: state => state.columnsModules,
    }),
    ...mapGetters('settingsDeviceModules', [
        'translatedAvailableDevicesModules',
    ]),
}
export const kitsSettingsDeviceModulesMethods = {
    ...mapActions('settingsDeviceModules', [
        'getDevicesModulesList',
        'setDevicesModulesSorting',
        'setCurPage',
        'updateModulesColumns',
        'updateModulesAvailable',
    ]),
}

export const kitsSettingsDevice = {
    ...mapState('settingsDevice', {
        devices: state => state.devices,
        devicesLoaded: state => state.devicesLoaded,
        devicesCurPage: state => state.devicesCurPage,
        devicesLimit: state => state.devicesLimit,
        totalDevices: state => state.totalDevices,
        totalPages: state => state.totalPages,
        devicesSorting: state => state.devicesSorting,
        availableDevices: state => state.availableDevices,
        columnsDevice: state => state.columnsDevice,
    }),
    ...mapGetters('settingsDevice', ['translatedAvailableDevices']),
}
export const kitsSettingsDeviceMethods = {
    ...mapActions('settingsDevice', [
        'getDevicesList',
        'setDevicesSorting',
        'setCurPage',
        'updateDevicesColumns',
        'updateDevicesAvailable',
    ]),
}

export const commonMethods = {
    ...mapActions('common', ['getLanguages']),
}

export const commonComputed = {
    ...mapState('common', {
        languagesList: state => state.languagesList,
    }),
}

// notifications

export const notificationsComputed = {
    ...mapGetters('notifications', ['info', 'error']),
}

export const notificationsMethods = {
    ...mapActions('notifications', ['setInfo', 'setError']),
}

// apk files

export const apkFilesComputed = {
    ...mapState('apk', {
        apkFilesList: state => state.apkFilesList,
        appListLoaded: state => state.appListLoaded,
        appListCurPage: state => state.appListCurPage,
        totalAppInList: state => state.totalAppInList,
        appListLimit: state => state.appListLimit,
        appListSorting: state => state.appListSorting,
        columnsMobile: state => state.columnsMobile,
        availableMobile: state => state.availableMobile,
        activeApp: state => state.activeApp,
    }),
    ...mapGetters('apk', [
        'translatedColumnsMobile',
        'translatedAvailableMobile',
    ]),
}

export const apkFilesMethods = {
    ...mapActions('apk', [
        'getFilesApk',
        'setActiveApp',
        'setAppsSorting',
        'setAppsCurPage',
        'updateMobileColumns',
    ]),
}
