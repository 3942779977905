import { createI18n } from 'vue-i18n'

import eng from '@/lang/eng.json'
import pol from '@/lang/pol.json'
import spa from '@/lang/spa.json'
import por from '@/lang/por.json'
import deu from '@/lang/deu.json'
import rus from '@/lang/rus.json'
import ukr from '@/lang/ukr.json'
import fra from '@/lang/fra.json'
import slo from '@/lang/slo.json'
import ita from '@/lang/ita.json'

let DEFAULT_LANGUAGE = window.localStorage.getItem('localeLang') || 'eng'

const i18n = createI18n({
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    locale: DEFAULT_LANGUAGE,
    messages: {
        eng: eng,
        pol: pol,
        spa: spa,
        por: por,
        deu: deu,
        rus: rus,
        ukr: ukr,
        fra: fra,
        slo: slo,
        ita: ita,
    },
})

export default i18n
