import { createStore } from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

let initialState = {}

Object.keys(modules).forEach(key => {
    initialState[key] = JSON.parse(JSON.stringify(modules[key].state))
})

const storageStage = createPersistedState({
    paths: ['auth'],
})

export default createStore({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    mutations: {
        RESET_STORE(state) {
            Object.keys(state).forEach(key => {
                state[key] = JSON.parse(JSON.stringify(initialState[key]))
            })
        },
    },
    plugins: [storageStage],
    strict: process.env.NODE_ENV !== 'production',
})

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
